import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';
import VisitorSectionWrapper, { SectionObject } from './demo.style';

import ImageOne from 'common/src/assets/image/saas/scheduleImg.jpg';
import TextHr from 'common/src/components/textLine';
import Calendly from '../calendly';

const requiredFields = [
  { key: 'message', label: 'Message' },
  { key: 'url', label: 'Website' },
  { key: 'name', label: 'Name' },
  { key: 'email', label: 'Email address' },
];

const initialState = {
  email: null,
  name: null,
  url: null,
  topic: null,
  message: null,
};

const ContactSection = ({ textArea, sectionSubTitle }) => {
  return (
    <VisitorSectionWrapper id="beyondABM">
      <SectionObject>
        <Fade left>
          <Image
            src={ImageOne}
            alt="VisitorDashboard1"
            style={{ margin: 'auto' }}
          />
        </Fade>
      </SectionObject>
      <Container>
        <Box {...textArea}>
          <div style={{ marginLeft: '20px' }}>
            <TextHr />
            <Text content="REQUEST DEMO" {...sectionSubTitle} />
          </div>

          <Calendly />
        </Box>
        {/* <Snackbar
          open={notification.open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={notification.sort}>
            {notification.text}
          </Alert>
        </Snackbar> */}
      </Container>
    </VisitorSectionWrapper>
  );
};

ContactSection.propTypes = {
  title: PropTypes.object,
  description: PropTypes.object,
  btnStyle: PropTypes.object,
};

ContactSection.defaultProps = {
  textArea: {
    width: ['100%', '100%', '65%'],
    ml: [0, 0, '45%'],
    mt: ['20px', '20px', 0],
  },
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#5268db',
    mb: '10px',
  },
  imageWrapper: {
    boxShadow: 'none',
  },
  title: {
    fontSize: ['20px', '20px', '24px', '24px', '28px'],
    fontWeight: '400',
    color: '#2a313c',
    letterSpacing: '-0.010em',
    mb: '20px',
    maxWidth: ['100%', '100%', '100%', '80%', '80%'],
    lineHeight: '1.5',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '33px',
    maxWidth: ['100%', '100%', '100%', '60%', '60%'],
  },
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
  },
};

export default ContactSection;
